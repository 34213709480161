<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="data"
      theme="nocturnal"
     
    >
       <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-on:click="changeStatus(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Cambiar Estado</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      
    </vue-good-table>
    
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import {BFormInput} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import {
   BDropdown, BDropdownItem,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown, 
    BDropdownItem,
    VueGoodTable,
    BFormInput
  },
  data () {
    return {
      columns: [
      {
        label: 'ID',
        field: 'id',
      },
      {
        label: 'Nome',
        field: 'name',
      },
      {
        label: 'Usernome',
        field: 'username',
      },   
      {
        label: 'Email',
        field: 'email',
      },  
      {
        label: 'Role',
        field: 'role',
      },   
      {
        label: 'Estado',
        field: 'status',
      },                  
      {
        label: 'Ações',
        field: 'actions',
      }
      ] , data:[]   }
  },
  created() {
     this.getList();
  },
  methods: {
    getList(){
      this.$http.get('/users/search')
     .then(res => {
       this.data = res.data
       
      })
    },
    changeStatus(user) {
      user.status = user.status==1?0:1;
      this.$http
        .post('/user/update', user)
        .then(response => {
          this.getList()
        })
    }
  },
}
</script>

<style>

</style>
